import {
  CompanyName,
  DefinedTrimedString,
  Lastname,
  PageBillingDetails,
  PageBillingDetailsId,
  RoFiscalCode,
  RoRegistrationNumber,
} from '@mero/api-sdk';
import { CityName, DistrictName } from '@mero/api-sdk/dist/business';
import { PageId } from '@mero/api-sdk/dist/pages';
import {
  Body,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H3s,
  Icon,
  Line,
  Select,
  Spacer,
  TextInput,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { Email, Firstname, ScaledNumber, StrictPhoneNumber } from '@mero/shared-sdk';
import { flow } from 'fp-ts/function';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import KeyboardAvoidingView from '../../../../../components/KeyboardAvoidingView';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';
import { useSafeInput } from '../../../../../hooks/useSafeInput';

import { meroApi } from '../../../../../contexts/AuthContext';
import log from '../../../../../utils/log';
import { nameGenerator } from '../../../../../utils/string';
import { styles } from './CompanyBillingDetails.style';
import DeleteBillingDetailsDialog from './DeleteBillingDetailsDialog';

const hasContactInfo = (details?: Partial<PageBillingDetails.Company['contact']>): boolean => {
  return Boolean(details?.firstname && details?.lastname && details?.phone && details?.email);
};

const INPUT_POSITIONS = {
  fiscalCode: 250,
  name: 350,
  regNo: 450,
  address: 550,
  firstname: 650,
  lastname: 750,
  phone: 850,
  email: 850,
} as const;

type Props = {
  pageId: PageId;
  defaultContact?: Partial<PageBillingDetails.Company['contact']>;
  billingDetailsId?: PageBillingDetailsId;
  details?: PageBillingDetails.Company;
  onSave: () => void;
  onDelete: () => void;
  canDelete: boolean;
};
const CompanyComponent: React.FC<React.PropsWithChildren<Props>> = ({
  pageId,
  defaultContact,
  billingDetailsId,
  details,
  onSave,
  onDelete,
  children,
  canDelete,
}) => {
  const { t } = useTranslation('subscription');
  const { isPhone } = useMediaQueries();

  const showError = useShowError();
  const toast = useToast();

  const block = React.useRef(false);

  const [showErrors, setShowErrors] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const [name, setName] = useSafeInput(NonEmptyString)(details?.name);
  const [registrationNumber, setRegistrationNumber] = useSafeInput(RoRegistrationNumber.JSON)(
    details?.registrationNumber,
  );
  const [fiscalCode, setFiscalCode] = useSafeInput(RoFiscalCode.JSON)(details?.fiscalCode);
  const [address, setAddress] = useSafeInput(NonEmptyString)(details?.address.address);

  const [showVatCheck, setShowVatCheck] = React.useState(typeof details?.isVatPayer !== 'undefined');
  const [vatCheck, setVatCheck] = React.useState(details?.isVatPayer ?? false);
  const [vatValue, setVatValue] = React.useState<number>();
  const [vatId, setVatId] = React.useState<string>('');

  const [district, setDistrict] = React.useState<NonEmptyString | undefined>(details?.address.county);
  const [city, setCity] = React.useState<NonEmptyString | undefined>(details?.address.city);

  const [districts, setDistricts] = React.useState<{ label: DistrictName; value: DistrictName }[]>([]);
  const [cities, setCities] = React.useState<{ label: CityName; value: CityName }[]>([]);

  const contactInfo = React.useMemo<PageBillingDetails.Company['contact']>(() => {
    return (
      details?.contact ?? {
        firstname: '' as Firstname,
        lastname: '' as Lastname,
        phone: '' as StrictPhoneNumber,
        email: '' as Email,
      }
    );
  }, []);

  const [firstname, setFirstname] = useSafeInput(NonEmptyString)(contactInfo.firstname || defaultContact?.firstname);
  const [lastname, setLastname] = useSafeInput(NonEmptyString)(contactInfo.lastname || defaultContact?.lastname);
  const [phone, setPhone] = useSafeInput(StrictPhoneNumber)(contactInfo.phone || defaultContact?.phone);
  const [email, setEmail] = useSafeInput(Email.JSON)(contactInfo.email || defaultContact?.email);

  const [isContactCollapsed, setIsContactCollapsed] = React.useState(
    hasContactInfo(contactInfo) || hasContactInfo(defaultContact),
  );

  const getInitialData = React.useCallback(async () => {
    const [districts, vatInfo] = await Promise.all([
      meroApi.business.getDistricts(),
      meroApi.checkout.getPageDefaults({
        pageId,
      }),
    ]);
    setVatValue(ScaledNumber.toNumber(vatInfo.vatRate));
    setDistricts(districts.map((d) => ({ label: d, value: d })));
  }, []);

  const getCities = async (county: string) => {
    const cities = await meroApi.business.getBillingCities({ district: county });
    setCities(cities.map((c) => ({ label: c, value: c })));
    if (cities.length === 1) {
      setCity(cities[0] as DefinedTrimedString);
    }
  };

  React.useEffect(() => {
    if (district) {
      getCities(district).catch((error) => log.error('Failed to get cities', error));
    }
  }, [district]);

  React.useEffect(() => {
    getInitialData().catch((error) => showError(error, t('failedToLoadDistricts')));
  }, []);

  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const save = async () => {
    if (
      !name.isValid ||
      !fiscalCode.isValid ||
      !registrationNumber.isValid ||
      !address.isValid ||
      !city ||
      !district ||
      !firstname.isValid ||
      !lastname.isValid ||
      !phone.isValid ||
      !email.isValid
    ) {
      !firstname.isValid || !lastname.isValid || !phone.isValid || !email.isValid ? setIsContactCollapsed(false) : null;
      setShowErrors(true);
      return;
    }
    try {
      setIsLoading(true);

      const billing = {
        type: 'Company',
        name: name.value as CompanyName,
        fiscalCode: fiscalCode.value,
        registrationNumber: registrationNumber.value,
        country: 'RO',
        iban: undefined,
        isVatPayer: vatCheck,
        address: {
          address: address.value as DefinedTrimedString,
          city: city as DefinedTrimedString,
          county: district as DefinedTrimedString,
        },
        contact: {
          firstname: firstname.value as Firstname,
          lastname: lastname.value as Lastname,
          phone: phone.value,
          email: email.value,
        },
      } as const;

      if (billingDetailsId) {
        await meroApi.pro.pageBillingDetails.updatePageBillingDetails({
          pageId,
          billingDetailsId,
          billing,
        });
        toast.show({
          type: 'success',
          text: t('billingDetailsUpdated'),
        });
      } else {
        await meroApi.pro.pageBillingDetails.addPageBillingDetails({
          pageId,
          billing,
        });

        toast.show({
          type: 'success',
          text: t('billingDetailsSaved'),
        });
      }

      onSave();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanyDetails = async () => {
    if (!fiscalCode.isValid) {
      return;
    }
    try {
      const [company] = await Promise.all([
        meroApi.checkout.getCompanyAutocomplete({
          pageId,
          cif: fiscalCode.value,
        }),
      ]);
      if (company) {
        if (!name.isValid) {
          setName(company.name);
        }

        if (!registrationNumber.isValid) {
          setRegistrationNumber(company.tradeRegisterNumber);
        }

        if (!address.isValid) {
          setAddress(company.address);
        }

        if (!district) {
          setDistrict((company.district as DefinedTrimedString) ?? undefined);

          if (company.district && company.city) {
            getCities(company.district)
              .then(() => setCity(company.city as DefinedTrimedString))
              .catch((error) => log.error('Failed to get cities', error));
          }
        }

        if (company.vatStatus.type === 'VatPayer') {
          setVatValue(ScaledNumber.toNumber(company.vatStatus.defaultRate));
        }

        setVatId(company.vatStatus.type === 'VatPayer' ? company.vatStatus.vatId : '');
        setVatCheck(company.vatStatus.type === 'VatPayer');
        setShowVatCheck(true);
      }
    } catch (error) {
      log.error('Failed to get company details', error);
    }
  };

  return (
    <>
      <KeyboardAvoidingView style={{ flex: 1 }}>
        <ScrollView>
          {children}
          <FormCard paddings={'none'} style={{ padding: 16, marginHorizontal: 16 }} dropShaddow rounded>
            <InputWithLabel
              label={t('fiscalCode')}
              isError={showErrors && !fiscalCode.isValid}
              errorText={t('fiscalCodeError')}
            >
              <TextInput
                value={fiscalCode.value}
                editable={!billingDetailsId}
                onChange={setFiscalCode}
                placeholder={t('fiscalCodePlaceholder')}
                onFocus={() => scrollTo('fiscalCode')}
                onBlur={!details ? getCompanyDetails : undefined}
                isError={showErrors && !fiscalCode.isValid}
              />
            </InputWithLabel>
            {showVatCheck && (
              <>
                <Spacer size={24} />
                <TouchableOpacity style={{ flexDirection: 'row' }}>
                  <Checkbox value={vatCheck} onValueChange={setVatCheck} />
                  <Title style={{ paddingLeft: 12 }}>
                    {vatCheck ? t('vatChecked', { value: vatValue }) : t('vatUnchecked')}
                  </Title>
                </TouchableOpacity>
              </>
            )}
            <Spacer size="16" />
            <InputWithLabel label={t('name')} isError={showErrors && !name.isValid} errorText={t('nameError')}>
              <TextInput
                value={name.value}
                onChange={setName}
                placeholder={t('namePlaceholder')}
                onFocus={() => scrollTo('name')}
                isError={showErrors && !name.isValid}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel
              label={t('regNo')}
              isError={showErrors && !registrationNumber.isValid}
              errorText={t('regNoError')}
            >
              <TextInput
                value={registrationNumber.value}
                onChange={setRegistrationNumber}
                placeholder={t('regNoPlaceholder')}
                onFocus={() => scrollTo('regNo')}
                isError={showErrors && !registrationNumber.isValid}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('address')} isError={showErrors && !address.isValid} errorText={t('addressError')}>
              <TextInput
                value={address.value}
                onChange={setAddress}
                placeholder={t('addressPlaceholder')}
                onFocus={() => scrollTo('address')}
                isError={showErrors && !address.isValid}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('county')} isError={showErrors && !district} errorText={t('countyError')}>
              <Select
                placeholder={t('countyPlaceholder')}
                items={districts}
                value={district}
                onChange={(value) =>
                  setDistrict((prev) => {
                    if (prev !== value) {
                      setCity(undefined);
                    }

                    return value as DefinedTrimedString;
                  })
                }
                isError={showErrors && !district}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('city')} isError={showErrors && !city} errorText={t('cityError')}>
              <Select
                placeholder={district ? t('cityPlaceholder2') : t('cityPlaceholder1')}
                items={cities}
                value={city}
                onChange={(city) => setCity(city as DefinedTrimedString)}
                isError={showErrors && !city}
                editable={cities.length > 0 && district !== undefined}
              />
            </InputWithLabel>
            <Spacer size="16" />
          </FormCard>
          <Spacer size={16} />
          <FormCard paddings={'none'} style={{ paddingVertical: 16, marginHorizontal: 16 }} dropShaddow rounded>
            {isContactCollapsed ? (
              <TouchableOpacity
                style={{ flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 16 }}
                onPress={() => setIsContactCollapsed(false)}
              >
                <Column>
                  <Title>{t('contactInfo')}</Title>
                  <Spacer size={8} />
                  <Body>
                    {nameGenerator({ firstname: firstname.value, lastname: lastname.value }, t('noName'))},{' '}
                    {phone.value},
                  </Body>
                  <Body>{email.value}</Body>
                </Column>
                <Icon type="down" />
              </TouchableOpacity>
            ) : (
              <>
                <H3s style={{ paddingHorizontal: 16 }}>{t('contactInfo')}</H3s>
                <Spacer size="16" />
                <Line />
                <Spacer size="16" />
                <Column style={{ paddingHorizontal: 16 }}>
                  <InputWithLabel
                    label={t('firstname')}
                    isError={showErrors && !firstname.isValid}
                    errorText={t('firstnameError')}
                  >
                    <TextInput
                      value={firstname.value}
                      onChange={setFirstname}
                      placeholder={t('firstnamePlaceholder')}
                      onFocus={() => scrollTo('firstname')}
                      isError={showErrors && !firstname.isValid}
                    />
                  </InputWithLabel>
                  <Spacer size="16" />
                  <InputWithLabel
                    label={t('lastname')}
                    isError={showErrors && !lastname.isValid}
                    errorText={t('lastnameError')}
                  >
                    <TextInput
                      value={lastname.value}
                      onChange={setLastname}
                      placeholder={t('lastnamePlaceholder')}
                      onFocus={() => scrollTo('lastname')}
                      isError={showErrors && !lastname.isValid}
                    />
                  </InputWithLabel>
                  <Spacer size="16" />
                  <InputWithLabel label={t('phone')} isError={showErrors && !phone.isValid} errorText={t('phoneError')}>
                    <TextInput
                      value={phone.value}
                      onChange={setPhone}
                      placeholder={t('phonePlaceholder')}
                      onFocus={() => scrollTo('phone')}
                      isError={showErrors && !phone.isValid}
                    />
                  </InputWithLabel>
                  <Spacer size="16" />
                  <InputWithLabel label={t('email')} isError={showErrors && !email.isValid} errorText={t('emailError')}>
                    <TextInput
                      value={email.value}
                      onChange={setEmail}
                      placeholder={t('emailPlaceholder')}
                      onFocus={() => scrollTo('email')}
                      isError={showErrors && !email.isValid}
                    />
                  </InputWithLabel>
                </Column>
              </>
            )}
          </FormCard>
          <Spacer size={32} />
          {canDelete ? (
            <Button
              backgroundColor={colors.ALABASTER}
              disabledBackgroundColor={colors.ALABASTER}
              color={colors.RADICAL_RED}
              text={t('deleteCompany')}
              onPress={() => setShowDeleteDialog(true)}
              disabled={isLoading}
            />
          ) : null}
          <Spacer size={117} />
        </ScrollView>
      </KeyboardAvoidingView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
      >
        <SafeAreaView edges={['bottom']}>
          <Button text={details ? t('saveChanges') : t('saveCompany')} onPress={save} disabled={isLoading} />
        </SafeAreaView>
      </FormCard>
      {showDeleteDialog && details && billingDetailsId ? (
        <DeleteBillingDetailsDialog
          billingDetailsId={billingDetailsId}
          type={details.type}
          onSuccess={flow(onCloseDeleteDialog, onDelete)}
          onCancel={onCloseDeleteDialog}
        />
      ) : null}
    </>
  );
};

export default CompanyComponent;
